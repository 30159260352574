<template>
    <ds-organism-content-column-checkmarks
        :headings-position="headingsPosition"
        :items="items"
        :media-position="mediaPosition"
        :media-size="mediaSize"
        :column-count="columnCount"
        :turn-on-background="turnOnBackground"
        :background="background"
        :icon-color="iconColor"
        :heading-color="headingColor"
        :subheading-color="subheadingColor"
        :title-color="titleColor"
        :title-hover-color="titleHoverColor"
        :title-active-color="titleActiveColor"
        :text-color="textColor"
        :button-color="buttonColor"
        :button-hover-color="buttonHoverColor"
        :button-active-color="buttonActiveColor"
    >
        <template
            v-if="heading"
            #heading="{ classes }"
        >
            <WatsonAtomDynamicText
                :text="heading"
                :typography="typography.heading"
                :classes="classes"
            />
        </template>

        <template
            v-if="subheading"
            #subheading="{ classes }"
        >
            <WatsonAtomDynamicText
                :text="subheading"
                :typography="typography.subheading"
                :classes="classes"
            />
        </template>

        <template #icon="{ item, classes }">
            <WatsonAtomImage
                v-if="item.image && item.mediaType === 'image'"
                :content="item.image"
                :class="classes"
            />

            <FontAwesomeIcon
                v-if="item.icon && item.mediaType === 'icon'"
                :icon="getIcon(item.icon)"
                :class="classes"
            />
        </template>

        <template #itemTitle="{ classes, title, url }">
            <Link
                v-if="url"
                :class="[classes, 'u-heading-4 u-bold']"
                :href="url.url"
            >
                {{ title }}
            </Link>

            <WatsonAtomDynamicText
                v-else
                :text="title"
                :typography="typography.title"
                :classes="classes"
            />
        </template>

        <template #itemText="{ classes, text }">
            <div
                :class="classes"
                v-html="text"
            ></div>
        </template>
    </ds-organism-content-column-checkmarks>
</template>

<script setup>
import { DsOrganismContentColumnCheckmarks } from 'design-system-component-library/marketplaces';
import WatsonAtomImage from '../../atoms/image/watson-atom-image.vue';
import WatsonAtomDynamicText from '../../atoms/dynamic-text/watson-atom-dynamic-text.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faCircleCheck, faHeart, faStar } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

defineProps({
    content: {
        type: Object,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
    heading: {
        type: String,
        required: false,
        default: null,
    },
    subheading: {
        type: String,
        required: false,
        default: null,
    },
    headingsPosition: {
        type: String,
        required: false,
        default: 'left',
    },
    columnCount: {
        type: String,
        required: false,
        default: '1',
    },
    mediaSize: {
        type: String,
        required: false,
        default: 'small',
    },
    mediaPosition: {
        type: String,
        required: false,
        default: 'above',
    },
    turnOnBackground: {
        type: Boolean,
        required: false,
        default: false,
    },
    background: {
        type: String,
        required: false,
        default: null,
    },
    iconColor: {
        type: String,
        required: false,
        default: null,
    },
    headingColor: {
        type: String,
        required: false,
        default: null,
    },
    subheadingColor: {
        type: String,
        required: false,
        default: null,
    },
    titleColor: {
        type: String,
        required: false,
        default: null,
    },
    titleHoverColor: {
        type: String,
        required: false,
        default: null,
    },
    titleActiveColor: {
        type: String,
        required: false,
        default: null,
    },
    textColor: {
        type: String,
        required: false,
        default: null,
    },
    buttonColor: {
        type: String,
        required: false,
        default: null,
    },
    buttonHoverColor: {
        type: String,
        required: false,
        default: null,
    },
    buttonActiveColor: {
        type: String,
        required: false,
        default: null,
    },
    typography: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});

const getIcon = icon => {
    switch (icon) {
        case 'checkmark':
            return faCheck;
        case 'checkmark-circle':
            return faCircleCheck;
        case 'circle-info':
            return faCircleInfo;
        case 'heart':
            return faHeart;
        case 'star':
            return faStar;
    }
};
</script>
